import { FaUsers, FaMoneyBill } from 'react-icons/fa';
import { useOutletContext, useParams, useNavigate, Link } from 'react-router-dom';
import { ImTicket } from 'react-icons/im';
import useCurrentDepartment from '../hooks/useCurrentDepartment';

const DashboardCards = ({ stats }) => {
  const { connectedUser = {}, isUserLoading } = useOutletContext();
  const { departmentId } = useParams();
  const navigate = useNavigate();
  const { currentDepartment, isLoading: isDepartmentLoading } = useCurrentDepartment(departmentId);

  function accessTicketsTaxes_handler(e) {
    return navigate(`../tickets/dashboard/${departmentId}`);
  }

  return !isUserLoading && !isDepartmentLoading && connectedUser && currentDepartment ? (
    !!+connectedUser?.user_is_admin || !!+connectedUser?.user_is_super_admin ? (
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8'>
        {!!+currentDepartment.departmentSettings.department_settings_is_tickets_active && (
          <div className='shadow-md rounded-xl p-6 flex flex-col gap-4 duration-300 ease-in-out bg-gradient-to-br from-primary-200/40 to-primary-300/50 border-[1px] border-primary-100'>
            <div className={`flex justify-start items-center gap-4`}>
              <ImTicket className='text-4xl text-primary-500' />
              <h3 className='subTitle tracking-wide text-primary-800'>Tichete</h3>
            </div>
            <div className='flex flex-col justify-center items-start'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas minima sint saepe praesentium
              perspiciatis, veritatis harum dignissimos libero id, earum temporibus cum illo ipsum qui accusantium
              voluptatum nostrum! Similique, soluta.
            </div>
            <div className='flex flex-col justify-end items-start justify-self-end'>
              <button
                className='text-lg font-medium btn btn-primary text-white justify-self-end'
                onClick={accessTicketsTaxes_handler}
              >
                Accesare
              </button>
            </div>
          </div>
        )}
        <div className='shadow-md rounded-xl p-6 flex flex-col justify-center self-start gap-4 duration-300 ease-in-out bg-gradient-to-br from-primary-200/40 to-primary-300/50 border-[1px] border-primary-100'>
          <div className='flex justify-center items-center gap-4'>
            <FaUsers className='text-4xl text-primary-500' />
            <h3 className='subTitle tracking-wide text-primary-800'>Utilizatori</h3>
          </div>
          <div className='flex justify-start items-center gap-1 divide-x-[0.1px] divide-primary-800/10'>
            <div className='flex flex-col items-center justify-center text-center flex-1'>
              <p className='uppercase font-medium text-sm text-primary-800/70'>Total</p>
              <span className='font-bold text-3xl text-primary-500'>{stats?.totalUsers}</span>
            </div>
            <div className='flex-col items-center justify-center text-center flex-1'>
              <p className='uppercase font-medium text-sm text-primary-800/70'>Ultimele 24h</p>
              <span className='font-bold text-3xl text-primary-500'>{stats?.lastDayUsers}</span>
            </div>
            <div className='flex-col items-center justify-center text-center flex-1'>
              <p className='uppercase font-medium text-sm text-primary-800/70'>Ultima luna</p>
              <span className='font-bold text-3xl text-primary-500'>{stats?.lastMonthUsers}</span>
            </div>
          </div>
        </div>
        <div className='shadow-md rounded-xl p-6 flex flex-col justify-center self-start gap-4 duration-300 ease-in-out bg-gradient-to-br from-green-200/70 to-green-300/80 border-[1px] border-green-200'>
          <div className='flex justify-center items-center gap-4'>
            <FaMoneyBill className='text-4xl text-green-500' />
            <h3 className='subTitle tracking-wide text-green-800'>Plati</h3>
          </div>
          <div className='flex justify-start items-center gap-1 divide-x-[0.1px] divide-green-800/10'>
            <div className='flex flex-col items-center justify-center text-center flex-1'>
              <p className='uppercase font-medium text-sm text-green-800/70'>Total</p>
              <span className='font-bold text-3xl text-green-500'>{stats?.totalPayments}</span>
            </div>
            <div className='flex-col items-center justify-center text-center flex-1'>
              <p className='uppercase font-medium text-sm text-green-800/70'>Ultimele 24h</p>
              <span className='font-bold text-3xl text-green-500'>{stats?.lastDayPayments}</span>
            </div>
            <div className='flex-col items-center justify-center text-center flex-1'>
              <p className='uppercase font-medium text-sm text-green-800/70'>Ultima luna</p>
              <span className='font-bold text-3xl text-green-500'>{stats?.lastMonthPayments}</span>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8'>
        {!!+currentDepartment.departmentSettings.department_settings_is_tickets_active && (
          <div className='shadow-md rounded-xl p-6 flex flex-col gap-4 duration-300 ease-in-out bg-gradient-to-br from-primary-200/40 to-primary-300/50 border-[1px] border-primary-100'>
            <div className={`flex justify-start items-center gap-4`}>
              <ImTicket className='text-4xl text-primary-500' />
              <h3 className='subTitle tracking-wide text-primary-800'>Tichete</h3>
            </div>
            <div className='flex flex-col justify-center items-start'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas minima sint saepe praesentium
              perspiciatis, veritatis harum dignissimos libero id, earum temporibus cum illo ipsum qui accusantium
              voluptatum nostrum! Similique, soluta.
            </div>
            <div className='flex flex-col justify-end items-start justify-self-end'>
              <button
                className='text-lg font-medium btn btn-primary text-white justify-self-end'
                onClick={accessTicketsTaxes_handler}
              >
                Accesare
              </button>
            </div>
          </div>
        )}
        <div className='shadow-md rounded-xl p-6 flex flex-col justify-center self-start gap-4 duration-300 ease-in-out bg-gradient-to-br from-green-200/70 to-green-300/80 border-[1px] border-green-200'>
          <div className='flex justify-center items-center gap-4'>
            <FaMoneyBill className='text-4xl text-green-500' />
            <h3 className='subTitle tracking-wide text-green-800'>Debite</h3>
          </div>
          <div className='flex justify-start items-center gap-1 divide-x-[0.1px] divide-green-800/10'>
            <div className='flex flex-col items-center justify-center text-center flex-1'>
              <p className='uppercase font-medium text-sm text-green-800/70'>Nr. roluri</p>
              <span className='font-bold text-3xl text-green-500'>{stats?.numberOfRoles}</span>
            </div>
            <div className='flex-col items-center justify-center text-center flex-1'>
              <p className='uppercase font-medium text-sm text-green-800/70'>Nr. debite</p>
              <span className='font-bold text-3xl text-green-500'>{stats?.numberOfDebits}</span>
            </div>
            <div className='flex-col items-center justify-center text-center flex-1'>
              <p className='uppercase font-medium text-sm text-green-800/70'>Total valoare</p>
              <span className='font-bold text-3xl text-green-500'>{stats?.debitsTotalValue}</span>
            </div>
          </div>
          <div className='flex items-start justify-center'>
            <Link
              to={`../taxes/${currentDepartment.department_id}`}
              className='btn bg-green-800 text-white font-medium text-lg hover:bg-green-700'
            >
              Vezi debite
            </Link>
          </div>
        </div>
      </div>
    )
  ) : (
    ''
  );
};

export default DashboardCards;

import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const statsApi = createApi({
  reducerPath: 'statsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window.API_URL,
    credentials: 'include'
  }),
  tags: ['Stats'],
  endpoints: (builder) => ({
    getStats: builder.query({
      query: () => '/stats.php',
      providesTags: ['Stats']
    })
  })
});

export const { useGetStatsQuery } = statsApi;

export default statsApi;

import { useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useGetAllUserTaxesQuery } from '../../api/taxesApi';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { getOmulonAddress } from '../../utils/fn';
import webincarolStatus from '../../consts/webincarol';
import useCurrentDepartment from '../../hooks/useCurrentDepartment';
import { toast } from 'react-toastify';

import { useGetConnectedUserQuery } from '../../api/authApi';
import { useCookies } from 'react-cookie';
import consts from '../../consts/consts';

import Spinner from '../../components/Spinner';

const TaxesPage = () => {
  const { departmentId } = useParams();

  const { currentDepartment, isLoading: isCurrentDepartmentLoading } = useCurrentDepartment(departmentId);

  // const { connectedUser: user, isUserLoading } = useOutletContext();

  const [cookies] = useCookies([consts.COOKIE_KEY_JWT]);

  const {
    data: user,
    isLoading: isUserLoading,
    refetch: refetchUser
  } = useGetConnectedUserQuery(cookies[consts.COOKIE_KEY_JWT]);

  const {
    data: userTaxes,
    isLoading: isUserTaxesLoading,
    refetch: refetchUserTaxes
  } = useGetAllUserTaxesQuery(user?.user_cui_cnp);

  const [isDebitModalInfoOpen, setIsDebitModalInfoOpen] = useState(false);

  const [isChangeCuiCnpModalOpen, setIsChangeCuiCnpModalOpen] = useState(false);

  const [isTaxesDataProcessing, setIsTaxesDataProcessing] = useState(false);

  const changeCuiCnpFormRef = useRef(null);

  const [taxesFiles, setTaxesFiles] = useState({});

  const [selectedDebit, setSelectedDebit] = useState(null);

  const hiddenPaymentFormRef = useRef(null);

  const navigate = useNavigate();

  if (!isUserLoading && (!!+user.user_is_admin || !!+user?.user_is_super_admin)) {
    navigate('/portal');
  }

  const payValues = useMemo(() => {
    const state = {};

    if (!isUserTaxesLoading && userTaxes?.roles && Object.keys(userTaxes.roles)) {
      Object.values(userTaxes.roles).forEach((role) => {
        role.debits.forEach((debit) => {
          if (!Boolean(+debit.debiton_is_processing)) {
            state[debit.debiton_id] = 0;
          }
        });
      });
    }

    return state;
  }, [isUserTaxesLoading, userTaxes]);

  const [valuesToPay, setValuesToPay] = useState(payValues);

  const totalValueToPay = useMemo(
    () =>
      Object.values(valuesToPay)?.reduce((prev, current) => {
        return prev + (parseFloat(current) || 0);
      }, 0) || '',
    [valuesToPay]
  );

  // total value of the taxes
  const totalValue = useMemo(() => {
    let value = 0;
    if (!isUserTaxesLoading && userTaxes?.roles && Object.keys(userTaxes.roles)) {
      Object.values(userTaxes?.roles).forEach((role) => {
        role.debits.forEach((debit) => {
          value += parseFloat(debit.debiton_wdeplata);
        });
      });
    }
    return value;
  }, [userTaxes?.roles, isUserTaxesLoading]);

  async function showDebitModalInfo_Handler(e, debit) {
    try {
      e.preventDefault();

      if (!!+debit.debiton_is_processing) {
        const res = await fetch(`${window.API_URL}/taxes/webincarol.php?debitKey=${debit.debiton_key}`, {
          method: 'GET',
          credentials: 'include'
        });
        const payments = await res.json();

        const paymentValueToBeProcessed =
          payments?.reduce(
            (acc, x) =>
              !Boolean(x.webincarol_is_confirmed) && +x.webincarol_status === webincarolStatus['SUCCESS']
                ? acc + parseFloat(x.webincarol_suminca)
                : 0,
            0
          ) || 0;
        debit = { ...debit, paymentValueToBeProcessed };
      }

      setSelectedDebit(debit);
      setIsDebitModalInfoOpen(true);
    } catch (error) {
      console.error(error);
    }
  }

  async function paymentHandler(e) {
    e.preventDefault();

    try {
      let totalValueToPay = Object.values(valuesToPay)?.reduce((prev, current) => {
        return prev + (parseFloat(current) || 0);
      }, 0);

      totalValueToPay = parseFloat(totalValueToPay);

      if (isNaN(totalValueToPay) || totalValueToPay <= 0) {
        return;
      }

      // add hidden inputs to the hidden form and submit it to create a post request
      const hiddenPaymentForm = hiddenPaymentFormRef.current;

      let inputs = `
        <input type="hidden" name="departmentId" value="${departmentId}" />
        <input type="hidden" name="userId" value="${user.user_id}" />
        <input type="hidden" name="totalValueToPay" value="${totalValueToPay}" />
      `;

      for (const [key, value] of Object.entries(valuesToPay)) {
        if (parseFloat(value) > 0) {
          inputs += `<input type="hidden" name="debitId-${key}" value="${value}" />`;
        }
      }

      hiddenPaymentForm.insertAdjacentHTML('afterbegin', inputs);

      return hiddenPaymentForm.submit();
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  async function process_taxes_data_handler(e) {
    try {
      e.preventDefault();
      setIsTaxesDataProcessing(true);
      const res = await fetch(window.API_URL + '/dev_tests/process_taxes_data.php', {
        credentials: 'include',
        method: 'POST'
      });
      const { success = false, message = '' } = await res.json();

      if (success) {
        toast.success(message);
        refetchUser(cookies[consts.COOKIE_KEY_JWT]);
        refetchUserTaxes(user?.user_cui_cnp);
        fetchTaxesFiles();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsTaxesDataProcessing(false);
    }
  }

  async function change_user_cuicnp_handler(e) {
    try {
      e.preventDefault();
      const formData = Object.fromEntries(new FormData(changeCuiCnpFormRef?.current).entries());
      formData.user_email = user?.user_email || '';
      const res = await fetch(window.API_URL + `/users.php?id=${user?.user_id}`, {
        method: 'PUT',
        body: JSON.stringify(formData),
        credentials: 'include'
      });
      const { success = false, message = '' } = await res.json();

      if (success) {
        // window.location.reload();
        toast.success(message);
        setIsChangeCuiCnpModalOpen(false);
        refetchUser(cookies[consts.COOKIE_KEY_JWT]);
        refetchUserTaxes(user?.user_cui_cnp);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchTaxesFiles() {
    fetch(window.API_URL + '/dev_tests/taxes_data_files.php', {
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((data) => {
        setTaxesFiles(data);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    let ignore = false;

    const fetchTaxesFiles = () =>
      fetch(window.API_URL + '/dev_tests/taxes_data_files.php')
        .then((res) => res.json())
        .then((data) => {
          if (!ignore) {
            setTaxesFiles(data);
          }
        })
        .catch((error) => console.error(error));

    fetchTaxesFiles();

    return () => {
      ignore = true;
    };
  }, [user]);

  useEffect(() => {
    let ignore = false;

    const fetchTaxesFiles = () =>
      fetch(window.API_URL + '/dev_tests/taxes_data_files.php', {
        credentials: 'include'
      })
        .then((res) => res.json())
        .then((data) => {
          if (!ignore) {
            setTaxesFiles(data);
          }
        })
        .catch((error) => console.error(error));

    const intervalId = setInterval(fetchTaxesFiles, 5000);

    return () => {
      ignore = true;
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      {/* hidden payment form */}
      <form
        className='hidden pointer-events-none'
        ref={hiddenPaymentFormRef}
        action={window.PAYMENT_URL}
        method='POST'
      ></form>
      {/* for dev */}
      {window.DEV_MODE === 'DEV' && (
        <>
          <div className='flex flex-col bg-gray-300/70 rounded shadow border-[1px] border-red-500 mb-4'>
            <div className='flex gap-8 items-center flex-wrap justify-center sm:justify-between px-2 py-4'>
              <button
                className='btn bg-white border-[1px] border-primary-500 text-lg hover:bg-white hover:text-black font-medium'
                onClick={(e) => {
                  setIsChangeCuiCnpModalOpen(true);
                  setTimeout(() => {
                    changeCuiCnpFormRef.current.elements[0].value = user?.user_cui_cnp;
                  }, 0);
                }}
              >
                Modificare CUI/CNP
              </button>
              <h1 className='text-5xl text-red-500 font-bold uppercase tracking-widest'>!Testare!</h1>
              <button
                className='btn bg-white border-[1px] border-primary-500 text-lg hover:bg-white hover:text-black font-medium'
                onClick={process_taxes_data_handler}
                disabled={isTaxesDataProcessing}
              >
                {isTaxesDataProcessing ? (
                  <>
                    <Spinner size={25} /> <span className='ml-2'>Procesare date</span>
                  </>
                ) : (
                  'Procesare date'
                )}
              </button>
            </div>
            <div className='flex gap-2 flex-wrap items-start justify-between bg-white px-2 py-4 border-t-[1px] border-t-red-500'>
              {Object.values(taxesFiles)?.length
                ? Object.entries(taxesFiles)?.map((item, idx) => (
                    <div key={idx}>
                      <h3 className='text-lg font-bold'>{item[0]}</h3>
                      <ul className='list-disc'>
                        {item[1]?.map((file, idx) => (
                          <li className='ml-8' key={idx}>
                            <a href={file.filePathWeb} target='_blank' rel='noreferrer' className='link'>
                              {file.fileName}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))
                : ''}
            </div>
          </div>
        </>
      )}
      {/* modal for changing cui/cnp */}
      <Modal
        open={isChangeCuiCnpModalOpen}
        onClose={(e) => {
          setIsChangeCuiCnpModalOpen(false);
        }}
      >
        <div className='modalContainer md:max-w-[600px]'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>Modificare CUI/CNP</h1>
            <button
              className='text-gray-100 hover:text-white text-xl'
              onClick={(e) => {
                setIsChangeCuiCnpModalOpen(false);
              }}
            >
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            <form ref={changeCuiCnpFormRef} className='w-full flex flex-col'>
              <div className='formControl'>
                <label className='formLabel'>CUI/CNP:</label>
                <input type='text' className={`formInput`} name='user_cui_cnp' />
              </div>
            </form>
          </div>
          <div className='modalFooter'>
            <button
              onClick={change_user_cuicnp_handler}
              type='submit'
              className='btn btn-primary self-start w-full xs:max-w-fit'
            >
              Modificare
            </button>
            <button
              className='btn btn-dark'
              onClick={() => {
                setIsChangeCuiCnpModalOpen(false);
              }}
            >
              Anulare
            </button>
          </div>
        </div>
      </Modal>
      {/* modal for dispaying the debit info */}
      <Modal
        open={isDebitModalInfoOpen}
        onClose={() => {
          setSelectedDebit(null);
          setIsDebitModalInfoOpen(false);
        }}
      >
        <div className='modalContainer md:max-w-[700px]'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>Detalii debit</h1>
            <button className='text-gray-100 hover:text-white text-xl' onClick={() => setIsDebitModalInfoOpen(false)}>
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            {selectedDebit && (
              <div>
                <h3 className='text-2xl font-bold uppercase mb-2'>
                  {selectedDebit.debiton_dendeb} ({selectedDebit.debiton_andeb})
                </h3>
                <div className='flex flex-col justify-center'>
                  <ul className='list-disc pl-8 space-y-2'>
                    <li className='text-lg'>
                      Valoare debit: <strong>{selectedDebit.debiton_sdebrol}</strong>
                    </li>
                    <li className='text-lg'>
                      Bonificatie: <strong>{selectedDebit.debiton_s_bonif}</strong>
                    </li>
                    <li className='text-lg'>
                      Majorare intarzieri: <strong>{selectedDebit.debiton_s_major}</strong>
                    </li>
                    <li className='text-lg'>
                      Sume incasate: <strong>{selectedDebit.debiton_s_incas}</strong>
                    </li>
                    <li className='text-lg'>
                      Datorat la zi: <strong>{selectedDebit.debiton_datorlazi}</strong>
                    </li>
                    {selectedDebit.debiton_scadbon > 0 && (
                      <li className='text-lg'>
                        Scadere bonificatie: <strong>{selectedDebit.debiton_scadbon}</strong>{' '}
                        <small className='text-red-500'>
                          (aplicabila ptr. plata integrala pana la data de 31.03.2023)
                        </small>
                      </li>
                    )}
                    <li className='text-xl mt-2 list-none'>
                      De plata: <strong>{selectedDebit.debiton_wdeplata} RON</strong>
                    </li>
                    {!!+selectedDebit.debiton_is_processing &&
                    parseFloat(selectedDebit.paymentValueToBeProcessed) > 0 ? (
                      <li className=' text-primary-500 text-lg mt-2 list-none'>
                        Plata online in curs de procesare:{' '}
                        <strong>{selectedDebit.paymentValueToBeProcessed} RON</strong>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className='modalFooter'>
            <button className='btn btn-dark' onClick={() => setIsDebitModalInfoOpen(false)}>
              Ok
            </button>
          </div>
        </div>
      </Modal>
      {!isUserTaxesLoading && userTaxes?.roles && (
        <div className='flex flex-col gap-4'>
          <h1 className='title'>{currentDepartment?.department_name} / Taxe si impozite locale</h1>
          <div className='rounded shadow p-3 bg-secondary-700 text-white flex flex-row justify-between items-center'>
            <div className='text-white flex flex-col justify-center'>
              <h3 className='text-xl font-medium leading-[1]'>
                {user?.user_firstname} {user?.user_lastname}
              </h3>
              <h5 className='text-sm leading-[1.3]'>{user?.user_cui_cnp}</h5>
            </div>
            <p className='font-bold text-lg'>Total de plata: {totalValue} LEI</p>
          </div>
          <div className='flex flex-col gap-4'>
            {Object.values(userTaxes?.roles)?.map((role, idx) => (
              <div key={idx} className='flex flex-col gap-4 justify-center'>
                <div className='rounded bg-secondary-100 p-3 px-2 text-secondary-500 font-bold border-[1px] border-secondary-200 text-lg'>
                  ROL {role.debits[0]?.debiton_rolnr} - {getOmulonAddress(role.omulon)}
                </div>
                <div className='flex flex-col gap-2'>
                  {role.debits.map((debit, idx) => (
                    <div
                      key={idx}
                      className='bg-white text-black border-[1px] border-secondary-200 p-2 flex justify-between items-center gap-2'
                    >
                      <div>
                        Nr. <span className='font-medium'>{debit.debiton_rolnr}</span>, Cap.{' '}
                        <span className='font-medium'>{debit.debiton_capitol}</span>, Scap.{' '}
                        <span className='font-medium'>{debit.debiton_scap}</span>, Den.{' '}
                        <span className='font-medium'>{debit.debiton_dendeb}</span> , Titular{' '}
                        <span className='font-medium'>{debit.debiton_numpre}</span>
                      </div>
                      <div className='flex gap-8 items-center'>
                        {Boolean(+debit.debiton_is_processing) ? (
                          <>
                            <span className='font-bold'>{debit.debiton_wdeplata} LEI</span>
                            <span className='text-primary-500 font-bold uppercase'>in curs de procesare</span>
                            <button
                              className='bg-transparent p-0'
                              onClick={(e) => showDebitModalInfo_Handler(e, debit)}
                            >
                              <AiFillQuestionCircle className='text-secondary-700 hover:scale-95 text-2xl duration-300 ease-in-out' />
                            </button>
                          </>
                        ) : (
                          <>
                            <span className='font-bold'>{debit.debiton_wdeplata} LEI</span>
                            <div className='flex gap-0'>
                              <input
                                type='number'
                                placeholder='Suma plata...'
                                className='border-[1px] border-black px-2 min-w-[8rem] rounded-sm'
                                min='0'
                                max={debit.debiton_wdeplata}
                                value={valuesToPay[debit.debiton_id] || ''}
                                onChange={(e) => {
                                  if (parseFloat(e.target.value) > parseFloat(debit.debiton_wdeplata)) {
                                    return;
                                  }

                                  setValuesToPay((prev) => ({
                                    ...valuesToPay,
                                    [debit.debiton_id]: parseFloat(e.target.value) || ''
                                  }));
                                }}
                              />
                              <button
                                className=''
                                onClick={(e) =>
                                  setValuesToPay((prev) => ({
                                    ...prev,
                                    [debit.debiton_id]:
                                      prev[debit.debiton_id] == debit.debiton_wdeplata ? '0' : debit.debiton_wdeplata
                                  }))
                                }
                              >
                                <BiLeftArrowAlt className='text-primary-500 hover:scale-95 text-2xl duration-300 ease-in-out' />
                              </button>
                            </div>
                            <button
                              className='bg-transparent p-0'
                              onClick={(e) => showDebitModalInfo_Handler(e, debit)}
                            >
                              <AiFillQuestionCircle className='text-secondary-700 hover:scale-95 text-2xl duration-300 ease-in-out' />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className='border-t-2 border-black'></div>
          <div className='w-full flex justify-end'>
            <span className='text-3xl font-bold'>Total plata: {totalValueToPay || 0} LEI</span>
          </div>
          <button
            className='btn btn-primary self-end text-3xl shadow-md py-3 px-12 w-full sm:w-fit'
            disabled={totalValueToPay <= 0 ? true : false}
            onClick={paymentHandler}
          >
            Plateste
          </button>
        </div>
      )}
    </div>
  );
};

export default TaxesPage;

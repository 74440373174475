import { Link, useOutletContext, useParams } from 'react-router-dom';
import useCurrentDepartment from '../../hooks/useCurrentDepartment';
import { useGetDepartmentTypedDocumentTypesQuery, useGetRequestTypesByDepartmentQuery } from '../../api/typesApi';
import { AiFillFile, AiOutlineClose } from 'react-icons/ai';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { BiTime } from 'react-icons/bi';
import { formatDate } from '../../utils/fn';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import { useAddInfoMutation, useGetInfosByDepartmentQuery } from '../../api/infosApi';
import { useAddTicketMutation } from '../../api/ticketsApi';
import consts from '../../consts/consts';
import { useNavigate } from 'react-router-dom';
import Lightbox from '../../components/Lightbox';

const DashboardTicketsPage = () => {
  const { departmentId } = useParams();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [isAddTicketModalOpen, setIsAddTicketModalOpen] = useState(false);

  const [addTicket] = useAddTicketMutation();

  const navigate = useNavigate();

  const { currentDepartment, isLoading: isDepartmentLoading } = useCurrentDepartment(departmentId);

  const { data: infos, isLoading: isInfosLoading } = useGetInfosByDepartmentQuery(departmentId);

  const { data: typedDocumentTypes, isLoading: isTypedDocumentTypesLoading } = useGetDepartmentTypedDocumentTypesQuery(
    currentDepartment?.department_id
  );

  const { data: requestTypes = [], isLoading: isRequestTypesLoading } = useGetRequestTypesByDepartmentQuery(
    currentDepartment?.department_id
  );

  const { connectedUser: user, isUserLoading } = useOutletContext();

  const formRef = useRef(null);
  const formTicketRef = useRef(null);
  const formFilesRef = useRef(null);
  const formFilesTicketRef = useRef(null);

  const [addInfo] = useAddInfoMutation();

  const [fileToDisplay, setFileToDisplay] = useState('');
  const [showLightbox, setShowLightbox] = useState(false);

  const schema = yup.object().shape({
    info_title: yup.string().required('Camp obligatoriu'),
    info_content: yup.string().required('Camp obligatoriu')
  });

  const schemaAddTicket = yup.object().shape({
    ticket_name: yup.string().required('Camp obligatoriu'),
    ticket_request_type_id: yup.string().required('Camp obligatoriu'),
    ticket_content: yup.string().required('Camp obligatoriu')
  });

  const {
    register,
    trigger,
    resetField,
    setValue,
    setError,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  const {
    register: register_2,
    trigger: trigger_2,
    resetField: resetField_2,
    setValue: setValue_2,
    setError: setError_2,
    formState: { errors: errors_2 }
  } = useForm({ resolver: yupResolver(schemaAddTicket) });

  function resetModalFields() {
    resetField('info_title');
    resetField('info_content');

    if (formFilesRef?.current) {
      formFilesRef.current.value = null;
    }
  }

  function resetModalFields_AddTicket() {
    resetField_2('ticket_name');
    resetField_2('ticket_request_type_id');
    resetField_2('ticket_content');

    if (formFilesTicketRef?.current) {
      formFilesTicketRef.current.value = null;
    }
  }

  function closeModals() {
    setIsAddModalOpen(false);
    resetModalFields();
    resetModalFields_AddTicket();
    setIsAddTicketModalOpen(false);
  }

  function showAddInfosModal_handler(e) {
    e.preventDefault();
    setIsAddModalOpen(true);
  }

  async function addInfo_handler(e) {
    e.preventDefault();

    try {
      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      const formData = new FormData(formRef.current);

      formData.append('info_department_id', departmentId);

      const files = formFilesRef?.current?.files;

      Array.from(files).forEach((file) => {
        formData.append('files[]', file);
      });

      const res = await addInfo(formData).unwrap();

      const { success = false, message = '', id = 0 } = res;

      if (success) {
        message && toast.success(message);
        closeModals();
      } else {
        message && toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function addTicket_handler(e) {
    e.preventDefault();

    try {
      const isValid = await trigger_2();

      if (!isValid) {
        return;
      }

      let res;
      const formData = new FormData(formTicketRef.current);

      formData.append('ticket_department_id', currentDepartment.department_id);
      formData.append('ticket_user_id', user?.user_id);

      const files = formFilesTicketRef?.current?.files;

      Array.from(files)?.forEach((file) => {
        formData.append('files[]', file);
      });

      res = await addTicket(formData).unwrap();

      const { success = false, message = '', id = 0 } = res;

      if (success) {
        localStorage.setItem(
          consts['LOCAL_STORAGE_KEY_PAGE_MESSAGE'],
          JSON.stringify({
            message
          })
        );
        navigate(`/tickets/${currentDepartment.department_id}/${id}`);
      } else {
        toast.error(message);
      }

      closeModals();
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  function setLightboxFile(e, file) {
    e.preventDefault();
    const fileExtension = file['web']['fileName'].substring(file['web']['fileName'].lastIndexOf('.') + 1);
    setFileToDisplay({
      fileExtension,
      filePath: file['web']['filePath'],
      fileName: file['web']['fileName']
    });
    setShowLightbox(true);
  }

  return (
    !isDepartmentLoading && (
      <>
        {showLightbox && <Lightbox file={fileToDisplay} setShowLightbox={setShowLightbox} />}
        {/* modal add ticket */}
        <Modal
          open={isAddTicketModalOpen}
          onClose={(e) => {
            closeModals();
          }}
        >
          <div className='modalContainer md:max-w-[600px]'>
            <div className='modalHeader'>
              <h1 className='subTitle text-white'>Adaugare solicitare</h1>
              <button
                className='text-gray-100 hover:text-white text-xl'
                onClick={(e) => {
                  closeModals();
                }}
              >
                <AiOutlineClose />
              </button>
            </div>
            <div className='modalBody'>
              <form ref={formTicketRef} className='w-full flex flex-col'>
                <div className='formControl'>
                  <label className='formLabel'>Denumire:</label>
                  <input
                    type='text'
                    className={`formInput ${errors_2?.ticket_name && 'border-[0.1px] border-primary-500'}`}
                    {...register_2('ticket_name')}
                  />
                  <p className='inputErrorMessage'>{errors_2?.ticket_name?.message}</p>
                </div>
                <div className='formControl'>
                  <label className='formLabel'>Tip solicitare:</label>
                  <select
                    className={`formInput ${errors_2?.ticket_request_type_id && 'border-[0.1px] border-primary-500'}`}
                    name=''
                    id=''
                    {...register_2('ticket_request_type_id')}
                    defaultValue={0}
                  >
                    <option value=''></option>
                    {!isRequestTypesLoading &&
                      requestTypes?.map((x) => (
                        <option value={x.request_type_id} key={x.request_type_id}>
                          {x.request_type_name}
                        </option>
                      ))}
                  </select>
                  <p className='inputErrorMessage'>{errors_2?.ticket_request_type_id?.message}</p>
                </div>
                <div className='formControl'>
                  <label>Continut:</label>
                  <textarea
                    className={`formInput ${errors_2?.ticket_content && 'border-[0.1px] border-primary-500'}`}
                    rows={3}
                    {...register_2('ticket_content')}
                  ></textarea>
                  <p className='inputErrorMessage'>{errors_2?.ticket_content?.message}</p>
                </div>
                <div className='flex flex-col'>
                  <label htmlFor='files'>Atasamente: </label>
                  <input type='file' multiple id='files' {...register_2('files')} ref={formFilesTicketRef} />
                </div>
              </form>
            </div>
            <div className='modalFooter'>
              <button
                onClick={(e) => addTicket_handler(e)}
                type='submit'
                className='btn btn-primary self-start w-full xs:max-w-fit'
              >
                Adaugare
              </button>
              <button
                className='btn btn-dark'
                onClick={() => {
                  closeModals();
                }}
              >
                Anulare
              </button>
            </div>
          </div>
        </Modal>
        {/* modal add info */}
        <Modal
          open={isAddModalOpen}
          onClose={(e) => {
            closeModals();
          }}
        >
          <div className='modalContainer md:max-w-[800px]'>
            <div className='modalHeader'>
              <h1 className='subTitle text-white'>Adaugare informare</h1>
              <button
                className='text-gray-100 hover:text-white text-xl'
                onClick={(e) => {
                  closeModals();
                }}
              >
                <AiOutlineClose />
              </button>
            </div>
            <div className='modalBody'>
              <form ref={formRef} className='w-full flex flex-col'>
                <div className='formControl'>
                  <label className='formLabel'>Titlu:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.info_title && 'border-[0.1px] border-primary-500'}`}
                    {...register('info_title')}
                  />
                  <p className='inputErrorMessage'>{errors?.info_title?.message}</p>
                </div>
                <div className='formControl'>
                  <label>Continut:</label>
                  <textarea
                    className={`formInput ${errors?.info_content && 'border-[0.1px] border-primary-500'}`}
                    rows={6}
                    {...register('info_content')}
                  ></textarea>
                  <p className='inputErrorMessage'>{errors?.info_content?.message}</p>
                </div>
                <div className='formControl'>
                  <label className='formLabel'>Atasamente ({consts['FILES_INPUT_TEXT_INFO']}):</label>
                  <input type='file' ref={formFilesRef} multiple />
                </div>
              </form>
            </div>
            <div className='modalFooter'>
              <button
                onClick={(e) => addInfo_handler(e)}
                type='submit'
                className='btn btn-primary self-start w-full xs:max-w-fit'
              >
                Adaugare
              </button>
              <button
                className='btn btn-dark'
                onClick={() => {
                  closeModals();
                }}
              >
                Anulare
              </button>
            </div>
          </div>
        </Modal>
        <div className='grid gap-x-4 md:gap-x-16 gap-y-4 grid-cols-12 justify-center items-start'>
          <h1 className='title col-span-12'>{currentDepartment?.department_name}</h1>
          <div className='col-span-12 flex flex-row justify-start gap-4'>
            {!isUserLoading && (!!+user?.user_is_super_admin || !!+user?.user_is_admin) && (
              <button className='btn btn-primary' onClick={showAddInfosModal_handler}>
                Adaugare informare
              </button>
            )}
            {!Boolean(user?.user_is_super_admin) && !Boolean(user?.user_is_admin) && (
              <button className='btn btn-primary' onClick={(e) => setIsAddTicketModalOpen(true)}>
                Adaugare solicitare
              </button>
            )}
            <Link to={`/tickets/${currentDepartment?.department_id}`} className='btn btn-dark'>
              Lista solicitari
            </Link>
          </div>
          <h2 className='subTitle col-span-12 mt-4'>Cele mai recente informatii</h2>
          <div className='lg:col-span-9 col-span-12 rounded flex gap-8 flex-col'>
            {!isInfosLoading &&
              !!infos?.length &&
              infos.map((info) => (
                <div className='flex flex-col gap-4'>
                  <div
                    className={`flex flex-col gap-1 shadow-md rounded translate-x-0 border-[0.1px] bg-gradient-to-br from-secondary-100/50 to-secondary-100/70 border-secondary-300`}
                  >
                    <div
                      className={`p-2 border-b-[0.1px] border-r-secondary-300 border-l-secondary-300 border-t-secondary-300 border-b-secondary-100`}
                    >
                      <div className='flex justify-between items-center flex-wrap'>
                        <span className='capitalize font-bold flex gap-2 text-2xl items-center text-secondary-600 break-all'>
                          {info.info_title}
                        </span>
                        <span className='text-secondary-500 flex gap-2 text-sm items-center'>
                          <BiTime /> {formatDate(info.info_created_at)}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>{info.info_content}</div>
                    {!!info?.files?.length && (
                      <div
                        className={`p-2 flex gap-2 flex-wrap border-t-[0.1px] border-r-secondary-300 border-l-secondary-300  border-b-secondary-300 border-t-secondary-100`}
                      >
                        {info?.files.map((file, idx) => (
                          <>
                            <a
                              key={idx}
                              href={file['web']['filePath']}
                              target='_blank'
                              title={file['web']['fileName']}
                              rel='noreferrer'
                              className='link flex items-center gap-1 break-all text-sm'
                              onClick={(e) => setLightboxFile(e, file)}
                            >
                              <AiFillFile />{' '}
                              {file['web']['fileName'].substring(0, file['web']['fileName'].lastIndexOf('.'))}
                            </a>
                            {idx < Object.values(info?.files)?.length - 1 && (
                              <span className='text-primary-500'>|</span>
                            )}
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <div className='col-span-12 lg:col-span-3 flex flex-col gap-2 md:gap-4'>
            <h4 className='font-semibold text-2xl'>Documente tipizate</h4>
            <ul className='flex flex-col gap-4 '>
              {!isTypedDocumentTypesLoading &&
                typedDocumentTypes?.map((x, idx) => (
                  <li
                    className='shadow rounded bg-white border-[0.1px] border-secondary-100'
                    key={x.typed_document_type_id}
                  >
                    <div className='flex flex-col justify-center items-start border-b-[0.1px] border-r-secondary-300 border-l-secondary-300 border-t-secondary-300 border-b-secondary-100 py-1 px-2'>
                      <h6 className='font-semibold text-sm text-left'>{x.typed_document_type_name}</h6>
                    </div>
                    <div className='text-sm p-2'>{x.typed_document_type_description}</div>
                    {x.files?.length ? (
                      <div className='flex flex-col justify-center items-start text-left border-t-[0.1px] border-r-secondary-300 border-l-secondary-300  border-b-secondary-300 border-t-secondary-100 py-1 px-2 flex-wrap'>
                        {x.files?.map((file, idx) => (
                          <a
                            key={idx}
                            href={file['web']['filePath']}
                            target='_blank'
                            rel='noreferrer'
                            className='link flex items-center gap-1 text-sm break-all'
                            title={file['web']['fileName']}
                            onClick={(e) => setLightboxFile(e, file)}
                          >
                            <AiFillFile />{' '}
                            {file['web']['fileName'].substring(0, file['web']['fileName'].lastIndexOf('.')).length > 25
                              ? file['web']['fileName'].substring(0, 25) + '...'
                              : file['web']['fileName'].substring(0, file['web']['fileName'].lastIndexOf('.'))}
                          </a>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </>
    )
  );
};

export default DashboardTicketsPage;

import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

const PermissionRoute = () => {
  const { connectedUser: user, isUserLoading } = useOutletContext();

  if ((!isUserLoading && !Boolean(+user?.user_is_activated_2)) || Boolean(+user?.user_is_admin)) {
    return <Navigate to='/dashboard' />;
  }

  return <>{!isUserLoading && <Outlet context={{ connectedUser: user, isUserLoading }} />}</>;
};

export default PermissionRoute;

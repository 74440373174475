import ReCAPTCHA from 'react-google-recaptcha';
import { createRef, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useGetConnectedUserQuery } from '../api/authApi';
import { useCookies } from 'react-cookie';
import consts from '../consts/consts';
import Spinner from '../components/Spinner';
import SuccessMessage from '../components/SuccessMessage';
import useCurrentDepartment from '../hooks/useCurrentDepartment';

const successTitle = 'Va multumim pentru mesajul dumneavoastra.';
const successSubtitle =
  'Confirmam primirea acestuia si va asiguram ca un membru al echipei noastre va raspunde cat mai curand posibil.';

const ContactPage = () => {
  const { currentDepartment, isLoading: isCurrentDepartmentLoading } = useCurrentDepartment();

  const [cookies] = useCookies([consts.COOKIE_KEY_JWT]);

  const { data: user, isLoading } = useGetConnectedUserQuery(cookies[consts.COOKIE_KEY_JWT]);

  const recaptchaRef = createRef();

  const fileRef = useRef(null);

  const [formInputs, setFormInputs] = useState({
    name: '',
    email: ''
  });

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const schema = yup
    .object({
      ...(!isLoading &&
        !user && {
          name: yup.string().required('Camp obligatoriu!'),
          email: yup.string().email('Email invalid!').required('Camp obligatoriu')
        }),
      message: yup.string().required('Camp obligatoriu!')
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(data) {
    setIsSubmitLoading(true);

    try {
      const formData = new FormData();

      // if (fileRef?.current?.files[0]) {
      //   formData.append('file', fileRef.current.files[0]);
      // }

      const files = fileRef?.current?.files;

      files?.length &&
        Array.from(files)?.forEach((file) => {
          formData.append('files[]', file);
        });

      for (const prop in data) {
        formData.append(`${prop}`, data[prop]);
      }

      if (user) {
        formData.set('name', user.user_is_pf ? `${user.user_firstname} ${user.user_lastname}` : user.user_company_name);
        formData.set('email', user.user_email);
      } else {
        formData.set('name', formInputs.name);
        formData.set('email', formInputs.email);
      }

      const res = await fetch(`${window.API_URL}/contact.php`, {
        method: 'POST',
        body: formData
      });
      const { success = false, message = '' } = await res.json();

      if (success) {
        setIsSubmitSuccess(true);

        if (fileRef?.current) {
          fileRef.current.value = null;
        }

        setFormInputs({ name: '', email: '' });
        resetField('message');
      } else {
        window.grecaptcha.reset();
        toast.error(message);
      }

      setIsSubmitLoading(false);
    } catch (error) {
      window.grecaptcha.reset();
      console.error(error);
    }
  }

  if (!isSubmitSuccess) {
    return (
      <div className='flex flex-col items-center justify-center h-full w-full minHeightCalc gap-4'>
        {currentDepartment && <h1 className='title self-start'>{currentDepartment.department_name} / Contact</h1>}
        <form className='form md:w-[30rem] gap-2' onSubmit={handleSubmit(onSubmit)}>
          <h1 className='subTitle self-start'>Contact</h1>
          <div className='formControl'>
            <label className='formLabel'>Nume:</label>
            <input
              type='text'
              className={`formInput ${errors?.name && 'border-[0.1px] border-primary-500'}`}
              {...register('name')}
              onChange={(e) => setFormInputs({ ...formInputs, [e.target.name]: e.target.value })}
              value={
                !isLoading && user
                  ? !Boolean(+user.user_is_pf)
                    ? user.user_company_name
                    : `${user.user_firstname} ${user.user_lastname}`
                  : formInputs.name
              }
              disabled={user ? true : false}
            />
            <p className='inputErrorMessage'>{errors?.name?.message}</p>
          </div>
          <div className='formControl'>
            <label className='formLabel'>Email:</label>
            <input
              type='text'
              className={`formInput ${errors?.email && 'border-[0.1px] border-primary-500'}`}
              {...register('email')}
              onChange={(e) => setFormInputs({ ...formInputs, [e.target.name]: e.target.value })}
              value={!isLoading && user ? user.user_email : formInputs.email}
              disabled={user ? true : false}
            />
            <p className='inputErrorMessage'>{errors?.email?.message}</p>
          </div>
          <div className='formControl'>
            <label className='formLabel'>Mesaj:</label>
            <textarea
              rows={5}
              className={`formInput ${errors?.message && 'border-[0.1px] border-primary-500'}`}
              {...register('message')}
            ></textarea>
            <p className='inputErrorMessage'>{errors?.message?.message}</p>
          </div>
          {!isLoading && user && (
            <div className='formControl'>
              <label className='formLabel' htmlFor='email-attachment'>
                Atasament ({consts['FILES_INPUT_TEXT_INFO']}):
              </label>
              <input type='file' ref={fileRef} id='email-attachment' multiple />
            </div>
          )}
          <div className='flex items-center self-start'>
            <button type='submit' className='btn btn-dark self-start w-full xs:max-w-fit flex mr-2'>
              Trimite
            </button>
            {isSubmitLoading && <Spinner />}
          </div>
        </form>
        <ReCAPTCHA
          ref={recaptchaRef}
          size='invisible'
          className='invisible opacity-0 pointer-events-none'
          sitekey={window.RECAPTCHA_SITE_KEY}
        />
      </div>
    );
  } else {
    return <SuccessMessage title={successTitle} subTitle={successSubtitle} />;
  }
};

export default ContactPage;

import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { AiFillDatabase } from 'react-icons/ai';
import { FaDownload } from 'react-icons/fa';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import { downloadFile } from '../utils/fn';

const SettingsDBOperations = () => {
  const [isCleanupDbConfirmationModalOpen, setIsCleanupDbConfirmationModalOpen] = useState(false);
  const [isInitDbConfirmationModalOpen, setIsInitDbConfirmationModalOpen] = useState(false);
  const [isBackupDbConfirmationModalOpen, setIsBackupDbConfirmationModalOpen] = useState(false);

  async function cleanup_database(e) {
    try {
      e.preventDefault();
      const res = await fetch(window.API_URL + '/db_operations/cleanup_db.php', {
        method: 'DELETE',
        credentials: 'include'
      });
      const { success = false, message = '' } = await res.json();

      if (success) {
        toast.success(message);
        setIsCleanupDbConfirmationModalOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function init_database(e) {
    try {
      e.preventDefault();
      const res = await fetch(window.API_URL + '/db_operations/init_db.php', {
        method: 'POST',
        credentials: 'include'
      });
      const { success = false, message = '' } = await res.json();

      if (success) {
        toast.success(message);
        setIsInitDbConfirmationModalOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function backup_database(e) {
    try {
      e.preventDefault();
      const res = await fetch(window.API_URL + '/db_operations/backup_db.php', {
        method: 'POST',
        credentials: 'include'
      });
      const { success = false, fileName = '', filePath = '', message = '' } = await res.json();

      if (success) {
        toast.success(message);
        downloadFile(fileName, filePath);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {/* modal for init the db */}
      <Modal open={isInitDbConfirmationModalOpen} onClose={() => setIsInitDbConfirmationModalOpen(false)}>
        <div className='modalContainer md:max-w-[600px]'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>Initializare baza de date</h1>
            <button
              className='text-gray-100 hover:text-white text-xl'
              onClick={() => setIsInitDbConfirmationModalOpen(false)}
            >
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            <p>Datele din baza de date vor fi initializate, sunteti sigur/a?</p>
          </div>
          <div className='modalFooter'>
            <button className='btn btn-primary' onClick={init_database}>
              Da
            </button>
            <button className='btn btn-dark' onClick={() => setIsInitDbConfirmationModalOpen(false)}>
              Anulare
            </button>
          </div>
        </div>
      </Modal>
      {/* modal for cleaning the db */}
      <Modal open={isCleanupDbConfirmationModalOpen} onClose={() => setIsCleanupDbConfirmationModalOpen(false)}>
        <div className='modalContainer md:max-w-[600px]'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>Golire baza de date</h1>
            <button
              className='text-gray-100 hover:text-white text-xl'
              onClick={() => setIsCleanupDbConfirmationModalOpen(false)}
            >
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            <p>Datele din baza de date vor fi sterse, sunteti sigur/a?</p>
          </div>
          <div className='modalFooter'>
            <button className='btn btn-primary' onClick={cleanup_database}>
              Da
            </button>
            <button className='btn btn-dark' onClick={() => setIsCleanupDbConfirmationModalOpen(false)}>
              Anulare
            </button>
          </div>
        </div>
      </Modal>
      <div className='flex flex-col gap-4 items-center justify-center'>
        {/* <h1 className='subTitle mt-16'>Operatii baza de date</h1> */}
        <div className='flex gap-8 md:gap-16 justify-center items-center flex-wrap md:mt-20 w-full'>
          <button
            className='btn btn-outline btn-lg gap-2 text-lg py-4 px-12 w-full md:max-w-fit'
            onClick={(e) => setIsInitDbConfirmationModalOpen(true)}
          >
            <AiFillDatabase /> Initializare baza de date
          </button>
          <button
            className='btn btn-outline btn-lg gap-2 text-lg py-4 px-12 w-full md:max-w-fit'
            onClick={(e) => setIsCleanupDbConfirmationModalOpen(true)}
          >
            <FaTrash />
            Golire baza de date
          </button>
          <button
            className='btn btn-outline btn-lg gap-2 text-lg py-4 px-12 w-full md:max-w-fit'
            onClick={backup_database}
          >
            <FaDownload />
            Backup
          </button>
        </div>
      </div>
    </>
  );
};

export default SettingsDBOperations;

import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RO } from 'material-react-table/locales/ro';
import Modal from '@mui/material/Modal';
import { useMemo, useState } from 'react';
import useCurrentDepartment from '../../hooks/useCurrentDepartment';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRef } from 'react';
import {
  useGetTypedDocumentTypesQuery,
  useAddTypedDocumentTypeMutation,
  useUpdateTypedDocumentTypeMutation,
  useRemoveTypedDocumentTypeMutation,
  useGetDepartmentTypedDocumentTypesQuery,
  useRemoveTypedDocumentTypeFileMutation
} from '../../api/typesApi';
import { FaTrash } from 'react-icons/fa';
import { AiFillEdit, AiOutlineClose } from 'react-icons/ai';
import { useGetTypedDocumentCategoriesQuery } from '../../api/categoriesApi';
import { useGetDepartmentRequestTypesQuery } from '../../api/typesApi';
import FilesList from '../../components/FilesList';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import consts from '../../consts/consts';
import Lightbox from '../../components/Lightbox';

const TypedDocumentTypesPage = ({ departmentId }) => {
  const { currentDepartment, isLoading: isDepartmentLoading } = useCurrentDepartment(departmentId);

  const { data: departmentRequestTypes, isLoading: isDepartmentRequestTypesLoading } =
    useGetDepartmentRequestTypesQuery(currentDepartment?.department_id);

  const attachmentsInputRef = useRef(null);

  const { data: typedDocumentTypes = [], isLoading: isTypedDocumentTypesLoading } =
    useGetDepartmentTypedDocumentTypesQuery(departmentId);

  const [addTypedDocumentType] = useAddTypedDocumentTypeMutation();

  const [updateTypedDocumentType] = useUpdateTypedDocumentTypeMutation();

  const [removeTypedDocumentType] = useRemoveTypedDocumentTypeMutation();

  const formRef = useRef(null);

  const [operatedRow, setOperatedRow] = useState(null);

  const [operatedRowId, setOperatedRowId] = useState(0);

  const [isAddUpdateModalOpen, setIsAddUpdateModalOpen] = useState(false);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const [removeTypedDocumentTypeFile] = useRemoveTypedDocumentTypeFileMutation();

  const [fileToDisplay, setFileToDisplay] = useState('');
  const [showLightbox, setShowLightbox] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'typed_document_type_name',
        header: 'Denumire',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>,
        size: 200
      },
      {
        accessorKey: 'typed_document_type_description',
        header: 'Descriere',
        size: 300
      },
      {
        accessorKey: 'request_type_name',
        header: 'Tip solicitare',
        size: 200
      }
    ],
    []
  );

  const schema = yup.object().shape({
    typed_document_type_name: yup.string().required('Camp obligatoriu'),
    typed_document_type_description: yup.string().required('Camp obligatoriu'),
    typed_document_type_request_type_id: yup.string().required('Camp obligatoriu')
    // files: yup.mixed().test('fileRequired', 'Camp obligatoriu', function (value) {
    //   return value && value.length > 0;
    // })
  });

  const {
    register,
    trigger,
    resetField,
    setValue,
    setError,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  function resetModalFields() {
    resetField('typed_document_type_name');
    resetField('typed_document_type_description');
    resetField('typed_document_type_request_type_id');
    resetField('files');
  }

  function closeModals() {
    setIsRemoveModalOpen(false);
    setIsAddUpdateModalOpen(false);
    setOperatedRow(null);
    resetModalFields();
  }

  async function removeTypedDocumentType_handler(data) {
    try {
      const res = await removeTypedDocumentType(data.typed_document_type_id).unwrap();
      const { success = false, message = '' } = res;
      if (success) {
        message && toast.success(message);
        closeModals();
      } else {
        message && toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function edit_handler(data) {
    setOperatedRowId(data.typed_document_type_id);
    setOperatedRow(data);
    setIsAddUpdateModalOpen(true);
    setValue('typed_document_type_name', data.typed_document_type_name);
    setValue('typed_document_type_description', data.typed_document_type_description);
    setValue('typed_document_type_request_type_id', data.typed_document_type_request_type_id);
  }

  async function addOrUpdate_handler(data) {
    try {
      let res;

      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      const formData = new FormData(formRef.current);

      const files = attachmentsInputRef?.current?.files;

      // if (!files || !files.length) {
      //   setError('files', { message: 'Camp obligatoriu', type: 'manual' });
      //   return;
      // }

      Array.from(files).forEach((file) => {
        formData.append('files[]', file);
      });

      if (operatedRow) {
        res = await updateTypedDocumentType({ data: formData, id: data.typed_document_type_id }).unwrap();
      } else {
        formData.append('typed_document_type_department_id', departmentId);
        res = await addTypedDocumentType(formData).unwrap();
      }

      const { success = false, message = '', id = 0 } = res;

      if (success) {
        message && toast.success(message);
        closeModals();
      } else {
        message && toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fileBoxClick_callback(e, file, fileBoxRef) {
    try {
      e.preventDefault();
      const res = await removeTypedDocumentTypeFile(file['server']['filePath']).unwrap();
      const { success = false, message = '' } = res;

      if (success) {
        fileBoxRef?.current?.remove();
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function setLightboxFile(e, file) {
    e.preventDefault();
    const fileExtension = file['web']['fileName'].substring(file['web']['fileName'].lastIndexOf('.') + 1);
    setFileToDisplay({
      fileExtension,
      filePath: file['web']['filePath'],
      fileName: file['web']['fileName']
    });
    setShowLightbox(true);
  }

  return (
    currentDepartment && (
      <>
        {showLightbox && <Lightbox file={fileToDisplay} setShowLightbox={setShowLightbox} />}
        <div className='flex flex-col gap-4'>
          {/* modal for removing the department */}
          <Modal open={isRemoveModalOpen} onClose={closeModals}>
            <div className='modalContainer md:max-w-[600px]'>
              <div className='modalHeader'>
                <h1 className='subTitle text-white'>Eliminare tip document tipizat</h1>
                <button className='text-gray-100 hover:text-white text-xl' onClick={closeModals}>
                  <AiOutlineClose />
                </button>
              </div>
              <div className='modalBody'>
                <p>
                  Tipul de document tipizat{' '}
                  <strong className='uppercase'>{operatedRow?.typed_document_type_name}</strong> va fi eliminat, sunteti
                  sigur?
                </p>
              </div>
              <div className='modalFooter'>
                <button className='btn btn-primary' onClick={() => removeTypedDocumentType_handler(operatedRow)}>
                  Da
                </button>
                <button className='btn btn-dark' onClick={closeModals}>
                  Anulare
                </button>
              </div>
            </div>
          </Modal>
          {/* modal for adding */}
          <Modal
            open={isAddUpdateModalOpen}
            onClose={(e) => {
              closeModals();
            }}
          >
            <div className='modalContainer md:max-w-[600px]'>
              <div className='modalHeader'>
                <h1 className='subTitle text-white'>
                  {operatedRow ? 'Modificare tip document tipizat' : 'Adaugare tip document tipizat'}
                </h1>
                <button
                  className='text-gray-100 hover:text-white text-xl'
                  onClick={(e) => {
                    closeModals();
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>
              <div className='modalBody'>
                <form ref={formRef} className='w-full flex flex-col'>
                  <div className='formControl'>
                    <label className='formLabel'>Denumire:</label>
                    <input
                      type='text'
                      className={`formInput ${errors?.typed_document_type_name && 'border-[0.1px] border-primary-500'}`}
                      {...register('typed_document_type_name')}
                    />
                    <p className='inputErrorMessage'>{errors?.typed_document_type_name?.message}</p>
                  </div>
                  <div className='form-control'>
                    <label>Tip solicitare:</label>
                    <select
                      defaultValue='0'
                      className={`formInput ${
                        errors?.typed_document_type_request_type_id && 'border-[0.1px] border-primary-500'
                      }`}
                      {...register('typed_document_type_request_type_id')}
                    >
                      <option value=''></option>
                      {departmentRequestTypes?.map((x) => (
                        <option value={x.request_type_id} key={x.request_type_id}>
                          {x.request_type_name}
                        </option>
                      ))}
                    </select>
                    <p className='inputErrorMessage'>{errors?.typed_document_type_request_type_id?.message}</p>
                  </div>
                  <div className='formControl'>
                    <label className='formLabel'>Descriere:</label>
                    <textarea
                      type='text'
                      className={`formInput ${
                        errors?.typed_document_type_description && 'border-[0.1px] border-primary-500'
                      }`}
                      rows={5}
                      {...register('typed_document_type_description')}
                    />
                    <p className='inputErrorMessage'>{errors?.typed_document_type_description?.message}</p>
                  </div>
                  <div className='formControl'>
                    <label className='formLabel'>Atasamente ({consts['FILES_INPUT_TEXT_INFO']}):</label>
                    <input type='file' multiple {...register('files')} ref={attachmentsInputRef} />
                    <p className='inputErrorMessage'>{errors?.files?.message}</p>
                  </div>
                  {operatedRow ? (
                    <Accordion sx={{ marginTop: '1rem', border: '0.1px solid #c2c2c2', boxShadow: 'none' }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography>Fisiere urcate</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FilesList
                          files={operatedRow?.files}
                          fileBoxClick_callback={fileBoxClick_callback}
                          fileBoxClick_lightbox={setLightboxFile}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    ''
                  )}
                </form>
              </div>
              <div className='modalFooter'>
                <button
                  onClick={(e) => addOrUpdate_handler(operatedRow)}
                  type='submit'
                  className='btn btn-primary self-start w-full xs:max-w-fit'
                >
                  {operatedRow ? 'Modificare' : 'Adaugare'}
                </button>
                <button
                  className='btn btn-dark'
                  onClick={() => {
                    closeModals();
                  }}
                >
                  Anulare
                </button>
              </div>
            </div>
          </Modal>
          <h1 className='subTitle'>Tipuri documente tipizate</h1>
          <div className='w-full'>
            <MaterialReactTable
              columns={columns}
              data={typedDocumentTypes ?? []}
              getRowId={(row) => row.typed_document_type_id}
              state={{ isTypedDocumentTypesLoading }}
              enableStickyHeader
              enableStickyFooter
              enableColumnResizing
              localization={MRT_Localization_RO}
              initialState={{
                showGlobalFilter: true,
                density: 'compact',
                columnPinning: { right: ['actions'] }
              }}
              // renderTopToolbarCustomActions={({ table }) => null}
              enablePinning
              // defaultColumn={{
              //   maxSize: 400,
              //   minSize: 80,
              //   size: 100 //default size is usually 180
              // }}
              enableDensityToggle={false}
              layoutMode='grid'
              enableRowActions
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: '', //change header text
                  minSize: 30,
                  maxSize: 300
                }
              }}
              renderRowActions={({ row: { original: data } }, index) => (
                <div className='flex gap-1'>
                  <button className='btn btn-outline btn-xm hover:scale-[0.99] p-1' onClick={(e) => edit_handler(data)}>
                    <AiFillEdit />
                  </button>
                  <button
                    className='btn btn-outline btn-xm hover:scale-[0.99] p-1'
                    onClick={(e) => {
                      setOperatedRowId(data.typed_document_type_id);
                      setOperatedRow(data);
                      setIsRemoveModalOpen(true);
                    }}
                  >
                    <FaTrash />
                  </button>
                </div>
              )}
              muiTableBodyRowProps={({ row: { original: data } }) => ({
                sx: {
                  ...(data.typed_document_type_id === operatedRowId && { background: '#fbd6d3' })
                },
                hover: false
              })}
              renderTopToolbarCustomActions={({ table }) => (
                <div>
                  <button className='btn btn-dark' onClick={(e) => setIsAddUpdateModalOpen(true)}>
                    Adaugare
                  </button>
                </div>
              )}
              positionActionsColumn='last'
              muiTableBodyCellProps={{
                sx: {
                  whiteSpace: 'pre-line'
                }
              }}
              muiTableHeadCellProps={{
                sx: {
                  // background: "#475569",
                  color: 'black',
                  fontWeight: 'bold'
                }
              }}
              muiTableContainerProps={{
                sx: {
                  maxHeight: '500px'
                }
              }}
              muiTablePaperProps={{
                sx: {
                  borderRadius: '0',
                  border: '0.1px solid #e0e0e0',
                  boxShadow: 'none'
                }
              }}
              muiTableProps={{
                sx: {
                  boxShadow: 'none'
                }
              }}
              muiTableHeadRowProps={{
                sx: {
                  boxShadow: 'none'
                }
              }}
            />
          </div>
        </div>
      </>
    )
  );
};

export default TypedDocumentTypesPage;

import React from 'react';

const PortalCard = ({ Icon = null, title = '', Button = null, description = '' }) => {
  return (
    <div className='shadow-md rounded-xl p-6 flex flex-col gap-4 duration-300 ease-in-out bg-gradient-to-br from-primary-200/40 to-primary-300/50 border-[1px] border-primary-100'>
      <div className={`flex ${Icon ? 'justify-center' : 'justify-start'} items-center gap-4`}>
        {Icon && <Icon className='text-4xl text-primary-500' />}
        <h3 className='subTitle tracking-wide text-primary-800'>{title}</h3>
      </div>
      <div className='flex flex-col justify-center items-start'>{description && description}</div>
      <div className='flex flex-col justify-end items-start justify-self-end'>{Button && Button}</div>
    </div>
  );
};

export default PortalCard;

import React from 'react';
import { useMemo } from 'react';

import PortalCard from './PortalCard';

import { useGetDepartmentsQuery } from '../api/departmentsApi';

import { useNavigate } from 'react-router-dom';
import { departmentTypes } from '../consts/departments';

const PortalCards = () => {
  const { data: departments = [], isLoading: isDepartmentsLoading } = useGetDepartmentsQuery();

  const navigate = useNavigate();

  const activeDepartments = useMemo(
    () =>
      departments
        ? departments.filter((department) => !!+department.departmentSettings.department_settings_is_department_active)
        : [],
    [departments]
  );

  function setDepartment(department) {
    if (department.department_type === departmentTypes['TAXES']) {
      navigate(`/dashboard/${department.department_id}`, { replace: true });
    } else if (department.department_type === departmentTypes['TICKETS']) {
      navigate(`/tickets/dashboard/${department.department_id}`, { replace: true });
    }
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8'>
      {!isDepartmentsLoading &&
        activeDepartments?.map((department) => (
          <PortalCard
            key={department.department_id}
            title={department.department_name}
            description={department.department_description}
            Button={
              <button
                className='text-lg font-medium btn btn-primary text-white justify-self-end'
                onClick={() => setDepartment(department)}
              >
                Accesare
              </button>
            }
          />
        ))}
    </div>
  );
};

export default PortalCards;

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import DashboardCards from '../../components/DashboardCards';
import { useGetStatsQuery } from '../../api/statsApi';
import { useEffect } from 'react';
import useCurrentDepartment from '../../hooks/useCurrentDepartment';

const DashboardPage = () => {
  const { connectedUser: user, isUserLoading } = useOutletContext();
  const { data: stats, isLoading: isStatsLoading } = useGetStatsQuery();
  const navigate = useNavigate();

  const { departmentId } = useParams();

  const { currentDepartment, isLoading: isCurrentDepartmentLoading } = useCurrentDepartment(departmentId);
  console.log(stats);
  useEffect(() => {
    if (
      !isUserLoading &&
      !Boolean(+user.user_is_admin) &&
      !Boolean(+user.user_is_super_admin) &&
      Boolean(+user.user_is_activated_2)
    ) {
      // return navigate(`/taxes/${departmentId}`);
    }
  }, [isUserLoading, navigate, user.user_is_admin, user.user_is_activated_2, user.user_is_super_admin, departmentId]);

  return (
    <div className='flex flex-col gap-4'>
      <h1 className='title'>{currentDepartment?.department_name} / Dashboard</h1>
      <DashboardCards stats={stats} />
    </div>
  );
};

export default DashboardPage;

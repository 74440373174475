import React, { useEffect, useRef, useState } from 'react';
import { subtractMonths, subtractDays, subtractHours, timeSince } from '../../utils/fn';
import { MdArrowBack } from 'react-icons/md';
import { AiFillCloseCircle, AiFillFile } from 'react-icons/ai';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import useCurrentDepartment from '../../hooks/useCurrentDepartment';
import consts from '../../consts/consts';
import { toast } from 'react-toastify';
import { useGetTicketQuery, useUpdateTicketMutation, useAddTicketContentMutation } from '../../api/ticketsApi';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { FaLockOpen, FaUserAlt } from 'react-icons/fa';
import { BiTime } from 'react-icons/bi';
import { HiLockClosed } from 'react-icons/hi';
import {
  useGetDepartmentTypedDocumentTypesQuery,
  useGetTypedDocumentTypesByDepartmentAndRequestTypeQuery
} from '../../api/typesApi';
import { getUsername } from '../../utils/fn';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Lightbox from '../../components/Lightbox';

const TicketPage = () => {
  const { ticketId } = useParams();

  const { data: ticket, isLoading: isTicketLoading } = useGetTicketQuery(ticketId);

  const { currentDepartment, isLoading: isDepartmentLoading } = useCurrentDepartment();

  const [updateTicket] = useUpdateTicketMutation();
  const [addTicketContent] = useAddTicketContentMutation();

  const { connectedUser: user, isUserLoading } = useOutletContext();

  const [fileToDisplay, setFileToDisplay] = useState('');
  const [showLightbox, setShowLightbox] = useState(false);

  const {
    data: typedDocumentTypes = [],
    isLoading: isTypedDocumentTypesLoading,
    refetch
  } = useGetTypedDocumentTypesByDepartmentAndRequestTypeQuery({
    departmentId: currentDepartment?.department_id,
    requestTypeId: ticket?.ticket_request_type_id
  });

  const formRef = useRef(null);
  const formFilesRef = useRef(null);

  const schema = yup.object().shape({
    ticket_content: yup.string().required('Camp obligatoriu')
  });

  const {
    register,
    trigger,
    resetField,
    setValue,
    setError,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (localStorage.getItem(consts['LOCAL_STORAGE_KEY_PAGE_MESSAGE'])) {
      const pageMessage = JSON.parse(localStorage.getItem(consts['LOCAL_STORAGE_KEY_PAGE_MESSAGE']));
      toast.success(pageMessage.message);
      localStorage.removeItem(consts['LOCAL_STORAGE_KEY_PAGE_MESSAGE']);
    }
  }, []);

  async function toggleTicket() {
    try {
      const formData = new FormData();
      formData.append('ticket_is_active', ticket.ticket_is_active == 0 ? 1 : 0);
      let res = await updateTicket({ data: formData, id: ticket.ticket_id }).unwrap();
      const { success = false } = res;
      success && toast.success(`Solicitarea a fost ${!!+ticket.ticket_is_active ? 'inchisa' : 'deschisa'}`);
      !success && toast.error(`Solicitarea nu a putut fi ${!!+ticket.ticket_is_active ? 'inchisa' : 'deschisa'}`);
    } catch (error) {
      console.error(error);
    }
  }

  async function addTicketContent_handler(e) {
    e.preventDefault();

    try {
      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      const formData = new FormData(formRef.current);
      formData.append('ticket_content_user_id', user.user_id);
      formData.append('ticket_content_ticket_id', ticketId);
      const files = formFilesRef?.current?.files;

      Array.from(files).forEach((file) => {
        formData.append('files[]', file);
      });

      const res = await addTicketContent(formData).unwrap();

      const { success = false, message = '', id = 0 } = res;

      if (success) {
        resetField('ticket_content');
        formFilesRef.current.value = '';
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function setLightboxFile(e, file) {
    e.preventDefault();
    const fileExtension = file['web']['fileName'].substring(file['web']['fileName'].lastIndexOf('.') + 1);
    setFileToDisplay({
      fileExtension,
      filePath: file['web']['filePath'],
      fileName: file['web']['fileName']
    });
    setShowLightbox(true);
  }

  return (
    !isTicketLoading &&
    !isUserLoading &&
    user &&
    ticket && (
      <>
        {showLightbox && <Lightbox file={fileToDisplay} setShowLightbox={setShowLightbox} />}
        <div className='grid gap-x-4 md:gap-x-16 gap-y-4 grid-cols-12 justify-center items-start'>
          <div className='col-span-12 flex flex-row justify-between'>
            <Link to={`/tickets/${currentDepartment?.department_id}`} className='btn btn-dark'>
              <MdArrowBack className='text-lg' /> Inapoi
            </Link>
            {!!+user.user_is_admin || !!+user.user_is_super_admin ? (
              !!+ticket.ticket_is_active ? (
                <button className='btn btn-primary gap-1' onClick={toggleTicket}>
                  <HiLockClosed /> Inchide tichet
                </button>
              ) : (
                <button className='btn btn-primary gap-1' onClick={toggleTicket}>
                  <FaLockOpen /> Deschide tichet
                </button>
              )
            ) : (
              ''
            )}
          </div>
          <div className='lg:col-span-9 col-span-12 rounded'>
            <div className='p-2 flex-wrap'>
              <h1 className='subTitle'>
                {ticket.ticket_number}
                {ticket.ticket_register_number ? `/${ticket.ticket_register_number}` : ''}/{ticket.ticket_name}{' '}
                {!!!+ticket.ticket_is_active && <span className='uppercase text-primary-500'>(inchis)</span>}
              </h1>
              {user.user_id != ticket.ticket_user_id && (
                <h1 className='subTitle capitalize'>
                  {!!+ticket.user_is_pf ? getUsername(ticket) : ticket.user_company_name}
                  {ticket.user_cui_cnp}
                </h1>
              )}
            </div>
            {ticket?.content?.length ? (
              <div className='mt-4 flex flex-col gap-4'>
                {ticket.content.map((x, idx) => (
                  <div
                    className={`flex flex-col gap-1 shadow-md rounded translate-x-0 border-[0.1px] bg-gradient-to-br ${
                      user.user_id == x.user_id
                        ? 'from-secondary-100/50 to-secondary-100/70 border-secondary-300 translate-x-0'
                        : 'from-primary-100/50 to-primary-100/70 border-primary-300 lg:translate-x-3'
                    }`}
                    key={x.ticket_content_id}
                  >
                    <div
                      className={`p-2 odd:border-b-[0.1px] ${
                        user.user_id == x.user_id
                          ? 'border-r-secondary-300 border-l-secondary-300 border-t-secondary-300 border-b-secondary-100'
                          : 'border-r-primary-300 border-l-primary-300 border-t-primary-300 border-b-primary-100'
                      }`}
                    >
                      <div className='flex justify-between items-center'>
                        <span className='capitalize font-medium flex gap-2 text-sm items-center text-secondary-600'>
                          <FaUserAlt
                            className={`${user.user_id == x.user_id ? 'text-secondary-600' : 'text-primary-500'}`}
                          />{' '}
                          {getUsername(x)} {user.user_id == x.user_id && <span className='lowercase'>(dvs.)</span>}
                        </span>
                        <span className='text-secondary-500 flex gap-2 text-sm items-center'>
                          <BiTime /> {timeSince(new Date(x.ticket_content_created_at))}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>{x.ticket_content}</div>
                    {!!x?.files?.length && (
                      <div
                        className={`p-2 flex gap-2 flex-wrap border-t-[0.1px] ${
                          user.user_id == x.user_id
                            ? 'border-r-secondary-300 border-l-secondary-300 border-b-secondary-300 border-t-secondary-100'
                            : 'border-r-primary-300 border-l-primary-300 border-b-primary-300 border-t-primary-100'
                        }`}
                      >
                        {x?.files.map((file, idx) => (
                          <>
                            <a
                              key={idx}
                              href={file['web']['filePath']}
                              target='_blank'
                              title={file['web']['fileName']}
                              rel='noreferrer'
                              className='link flex items-center gap-1 break-all text-sm'
                              onClick={(e) => setLightboxFile(e, file)}
                            >
                              <AiFillFile />{' '}
                              {file['web']['fileName'].substring(0, file['web']['fileName'].lastIndexOf('.'))}
                            </a>
                            {idx < Object.values(x?.files)?.length - 1 && <span className='text-primary-500'>|</span>}
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className='alert alertInfo col-span-12 mt-4'>
                <BsFillInfoCircleFill />
                {!!+ticket.ticket_is_active
                  ? 'Pentru moment acest ticket este gol, puteti trimite un mesaj prin formularul de mai jos!'
                  : 'Va rugam asteptati pana in momentul in care solicitarea dvs. va fi preluata de catre un operator al primariei!'}
              </div>
            )}
            {!!+ticket.ticket_is_active && (
              <form
                className='flex flex-col gap-2 md:gap-0 mt-4 p-1 shadow-sm rounded border-[1px] border-secondary-300'
                ref={formRef}
              >
                <div className='flex flex-col md:flex-row w-full md:gap-4 gap-0'>
                  <div className='formControl'>
                    <textarea
                      className={`flex-1 border-[1px] w-full  rounded p-2 ${
                        errors?.ticket_content ? 'border-[0.1px] border-primary-500' : 'border-secondary-200'
                      }`}
                      rows={3}
                      {...register('ticket_content')}
                    ></textarea>
                    <p className='inputErrorMessage'>{errors?.ticket_content?.message}</p>
                  </div>
                  <button
                    className='btn btn-primary flex-0 h-min py-4 md:py-8 text-lg font-medium'
                    onClick={addTicketContent_handler}
                  >
                    Trimite
                  </button>
                </div>
                <div className='flex flex-col'>
                  <label htmlFor='files'>Atasamente ({consts['FILES_INPUT_TEXT_INFO']}):</label>
                  <input type='file' multiple id='files' ref={formFilesRef} />
                </div>
              </form>
            )}
          </div>
          <div className='col-span-12 lg:col-span-3 flex flex-col gap-4 md:gap-8'>
            <h3 className='subTitle'>Documente tipizate</h3>
            <ul className='flex flex-col gap-4 '>
              {!isTypedDocumentTypesLoading &&
                typedDocumentTypes?.map((x, idx) => (
                  <li
                    className='shadow rounded bg-white border-[0.1px] border-secondary-100'
                    key={x.typed_document_type_id}
                  >
                    <div className='flex flex-col justify-center items-start border-b-[0.1px] border-r-secondary-300 border-l-secondary-300 border-t-secondary-300 border-b-secondary-100 py-1 px-2'>
                      <h6 className='font-semibold text-sm text-left'>{x.typed_document_type_name}</h6>
                    </div>
                    <div className='text-sm p-2'>{x.typed_document_type_description}</div>
                    {x.files?.length ? (
                      <div className='flex flex-col justify-center items-start text-left border-t-[0.1px] border-r-secondary-300 border-l-secondary-300  border-b-secondary-300 border-t-secondary-100 py-1 px-2 flex-wrap w-full'>
                        {x.files?.map((file, idx) => (
                          <a
                            key={idx}
                            href={file['web']['filePath']}
                            target='_blank'
                            rel='noreferrer'
                            className='link flex items-center gap-1 text-sm break-all'
                            title={file['web']['fileName']}
                            onClick={(e) => setLightboxFile(e, file)}
                          >
                            <AiFillFile />{' '}
                            {file['web']['fileName'].substring(0, file['web']['fileName'].lastIndexOf('.')).length > 25
                              ? file['web']['fileName'].substring(0, 25) + '...'
                              : file['web']['fileName'].substring(0, file['web']['fileName'].lastIndexOf('.'))}
                          </a>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </>
    )
  );
};

export default TicketPage;

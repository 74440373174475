import { Navigate, Outlet } from 'react-router-dom';
import { useGetConnectedUserQuery } from '../../api/authApi';
import { useCookies } from 'react-cookie';
import { RiErrorWarningFill } from 'react-icons/ri';
import consts from '../../consts/consts';
import { useParams } from 'react-router-dom';
import useCurrentDepartment from '../../hooks/useCurrentDepartment';

const PrivateRoute = () => {
  const [cookies] = useCookies([consts.COOKIE_KEY_JWT]);

  const { departmentId = null } = useParams();

  const { currentDepartment = null, isLoading: isDepartmentLoading } = useCurrentDepartment(departmentId);

  const { data: user, isLoading } = useGetConnectedUserQuery(cookies[consts.COOKIE_KEY_JWT]);

  if (!isLoading && !user) {
    return <Navigate to='/' />;
  }

  return (
    <>
      {!isLoading &&
      !Boolean(+user?.user_is_activated_2) &&
      currentDepartment &&
      !!+currentDepartment?.departmentSettings?.department_settings_user_needs_activation ? (
        <div className='alert alertDanger flex gap-2 items-center mb-6'>
          <RiErrorWarningFill className='text-xl' />
          <p>
            In prezent, contul dumneavoastra este evaluat de un reprezentant al institutiei noastre. Dupa aprobarea
            contului, veti putea accesa obligatiunile de plata.
          </p>
        </div>
      ) : (
        ''
      )}
      {!isLoading && <Outlet context={{ connectedUser: user, isUserLoading: isLoading }} />}
    </>
  );
};

export default PrivateRoute;

import { GiHamburgerMenu } from 'react-icons/gi';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import authApi, { useGetConnectedUserQuery, useLogoutMutation } from '../api/authApi';
import { useCookies } from 'react-cookie';
import { FiLogOut } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { FaUserCircle, FaMoneyCheck } from 'react-icons/fa';
import { MdManageAccounts } from 'react-icons/md';
import { AiFillHome, AiFillInfoCircle, AiOutlineInfoCircle, AiTwotoneMail } from 'react-icons/ai';
import { FaCog } from 'react-icons/fa';
import consts from '../consts/consts';
import { ImBook } from 'react-icons/im';
import useCurrentDepartment from '../hooks/useCurrentDepartment';
import { departmentTypes } from '../consts/departments';
import { ImTicket } from 'react-icons/im';
// remove contact link from

const Navbar = ({ hallName, logoUrl }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const menuContainer = useRef(null);
  const [cookies, removeCookie] = useCookies([consts.COOKIE_KEY_JWT]);
  const { data: user, isLoading } = useGetConnectedUserQuery(cookies[consts.COOKIE_KEY_JWT]);
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentDepartment = null, isLoading: isCurrentDepartmentLoading } = useCurrentDepartment();
  const location = useLocation();
  const basePath = location.pathname.split('/').filter((val) => val !== '')[0];

  useEffect(() => {
    function keyDown_handler(e) {
      if (e.code === 'Escape') {
        setIsMenuOpen(false);
      }
    }
    window.addEventListener('keydown', keyDown_handler);
    return () => window.removeEventListener('keydown', keyDown_handler);
  }, []);

  useEffect(() => {
    function windowClick_handler(e) {
      if (!menuContainer?.current?.contains(e.target)) {
        setIsMenuOpen(false);
      }
    }
    window.addEventListener('click', windowClick_handler);
    return () => window.removeEventListener('click', windowClick_handler);
  }, []);

  async function logoutUser(e) {
    e.preventDefault();
    removeCookie(consts.COOKIE_KEY_JWT, null);
    dispatch(authApi.util.resetApiState());
    await logout();
    navigate('/');
    window.location.reload();
  }

  let dashboardLink = '/';

  if (!isLoading && user) {
    if (currentDepartment?.department_type === departmentTypes['TAXES']) {
      dashboardLink = `/dashboard/${currentDepartment.department_id}`;
    } else if (currentDepartment?.department_type === departmentTypes['TICKETS']) {
      dashboardLink = `/tickets/dashboard/${currentDepartment.department_id}`;
    } else {
      dashboardLink = '/portal';
    }
  }

  return (
    <header className='bg-primary-500 flex items-center justify-center shadow w-full relative top-0 left-0 right-0 overflow-visible h-16 z-50'>
      <nav className='flexBetween flex-1 container'>
        <Link to={'/portal'} className='flex items-center justify-center gap-2 md:gap-4'>
          <img className=' pt-2' src={logoUrl} alt='stema primarie' width='45' height='45' />
          <div className='flex flex-col gap-0'>
            <h1 className='text-white uppercase text-xs xs:text-xl md:text-2xl font-bold'>{hallName}</h1>
            <h3 className='text-white uppercase text-[0.5rem] xs:text-lg italic md:text-sm font-bold'>
              {!isCurrentDepartmentLoading && currentDepartment ? currentDepartment.department_name : 'portal online'}
            </h3>
          </div>
        </Link>
        {!isLoading && user ? (
          <div className='relative' ref={menuContainer}>
            <button className='text-white text-3xl md:text-4xl relative' onClick={() => setIsMenuOpen((prev) => !prev)}>
              <GiHamburgerMenu />
            </button>
            {isMenuOpen &&
              (currentDepartment.department_type === departmentTypes['TAXES'] ? (
                basePath === 'tickets' ? (
                  <ul
                    className='bg-white shadow-md border-[0.1px] border-dark-100 rounded absolute flex flex-col flex-1 min-w-[14rem] sm:min-w-[12rem] w-fit items-start justify-center right-0 top-12 sm:top-10 max-h-[75vh] xs:max-h-[50vh] overflow-y-auto'
                    ref={menuRef}
                  >
                    <Link
                      to={`/portal`}
                      className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                    >
                      <AiFillHome className='text-primary-500 group-hover:text-white' />
                      <span>Acasa</span>
                    </Link>
                    <Link
                      to={`/tickets/dashboard/${currentDepartment?.department_id}`}
                      className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                    >
                      <AiFillInfoCircle className='text-primary-500 group-hover:text-white' />
                      <span>Informatii</span>
                    </Link>
                    <Link
                      to={`/tickets/${currentDepartment?.department_id}`}
                      className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                    >
                      <ImTicket className='text-primary-500 group-hover:text-white' />
                      <span>Solicitari</span>
                    </Link>
                    <button
                      className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                      onClick={logoutUser}
                    >
                      <FiLogOut className='text-primary-500 group-hover:text-white' />
                      <span>Deconectare</span>
                    </button>
                  </ul>
                ) : (
                  <ul
                    className='bg-white shadow-md border-[0.1px] border-dark-100 rounded absolute flex flex-col flex-1 min-w-[14rem] sm:min-w-[12rem] w-fit items-start justify-center right-0 top-12 sm:top-10 max-h-[75vh] xs:max-h-[50vh] overflow-y-auto'
                    ref={menuRef}
                  >
                    <Link
                      to={`/portal`}
                      className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                    >
                      <AiFillHome className='text-primary-500 group-hover:text-white' />
                      <span>Acasa</span>
                    </Link>
                    {!isLoading &&
                      user &&
                      Boolean(+user.user_is_activated_2) &&
                      !Boolean(+user.user_is_admin) &&
                      !Boolean(+user.user_is_super_admin) && (
                        <>
                          <Link
                            to={`/taxes/${currentDepartment?.department_id}`}
                            className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                          >
                            <FaMoneyCheck className='text-primary-500 group-hover:text-white' />
                            <span>Taxe si impozite</span>
                          </Link>
                        </>
                      )}
                    {!isLoading &&
                      (Boolean(+user?.user_is_activated_2) ||
                        Boolean(+user?.user_is_admin) ||
                        !!+user?.user_is_super_admin) && (
                        <Link
                          to={`/paymenthistory/${currentDepartment?.department_id}`}
                          className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                        >
                          <FaMoneyCheck className='text-primary-500 group-hover:text-white' />
                          <span>Istoric plati</span>
                        </Link>
                      )}
                    {!isLoading && (Boolean(+user?.user_is_admin) || !!+user?.user_is_super_admin) && (
                      <>
                        <Link
                          to={`/eventslogs/${currentDepartment?.department_id}`}
                          className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                        >
                          <ImBook className='text-primary-500 group-hover:text-white' />
                          <span>Jurnal evenimente</span>
                        </Link>
                      </>
                    )}
                    {!isLoading && !Boolean(+user?.user_is_admin) && !Boolean(+user?.user_is_super_admin) && (
                      <Link
                        to={`/contact/${currentDepartment?.department_id}`}
                        className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                      >
                        <AiTwotoneMail className='text-primary-500 group-hover:text-white' />
                        <span>Contact</span>
                      </Link>
                    )}
                    <button
                      className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                      onClick={logoutUser}
                    >
                      <FiLogOut className='text-primary-500 group-hover:text-white' />
                      <span>Deconectare</span>
                    </button>
                  </ul>
                )
              ) : currentDepartment.department_type === departmentTypes['TICKETS'] ? (
                <ul
                  className='bg-white shadow-md border-[0.1px] border-dark-100 rounded absolute flex flex-col flex-1 min-w-[14rem] sm:min-w-[12rem] w-fit items-start justify-center right-0 top-12 sm:top-10 max-h-[75vh] xs:max-h-[50vh] overflow-y-auto'
                  ref={menuRef}
                >
                  <Link
                    to={`/portal`}
                    className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                  >
                    <AiFillHome className='text-primary-500 group-hover:text-white' />
                    <span>Acasa</span>
                  </Link>
                  <Link
                    to={`/tickets/dashboard/${currentDepartment?.department_id}`}
                    className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                  >
                    <AiFillInfoCircle className='text-primary-500 group-hover:text-white' />
                    <span>Informatii</span>
                  </Link>
                  <Link
                    to={`/tickets/${currentDepartment?.department_id}`}
                    className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                  >
                    <ImTicket className='text-primary-500 group-hover:text-white' />
                    <span>Solicitari</span>
                  </Link>
                  <button
                    className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                    onClick={logoutUser}
                  >
                    <FiLogOut className='text-primary-500 group-hover:text-white' />
                    <span>Deconectare</span>
                  </button>
                </ul>
              ) : (
                <ul
                  className='bg-white shadow-md border-[0.1px] border-dark-100 rounded absolute flex flex-col flex-1 min-w-[14rem] sm:min-w-[12rem] w-fit items-start justify-center right-0 top-12 sm:top-10 max-h-[75vh] xs:max-h-[50vh] overflow-y-auto'
                  ref={menuRef}
                >
                  <Link
                    to={`/portal`}
                    className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                  >
                    <AiFillHome className='text-primary-500 group-hover:text-white' />
                    <span>Acasa</span>
                  </Link>
                  {(!!+user.user_is_super_admin || !!+user.user_is_admin) && (
                    <Link
                      to='/users'
                      className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                    >
                      <MdManageAccounts className='text-primary-500 group-hover:text-white' />
                      <span>Utilizatori</span>
                    </Link>
                  )}
                  <Link
                    to='/profile'
                    className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                  >
                    <FaUserCircle className='text-primary-500 group-hover:text-white' />
                    <span>Profil</span>
                  </Link>
                  {!isLoading && (Boolean(+user?.user_is_admin) || !!+user?.user_is_super_admin) && (
                    <>
                      <Link
                        to={`/logs`}
                        className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                      >
                        <ImBook className='text-primary-500 group-hover:text-white' />
                        <span>Jurnal</span>
                      </Link>
                    </>
                  )}
                  {!isLoading && Boolean(+user.user_is_super_admin) && (
                    <>
                      <Link
                        to='/settings'
                        className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                      >
                        <FaCog className='text-primary-500 group-hover:text-white' />
                        <span>Setari</span>
                      </Link>
                    </>
                  )}
                  <Link
                    to={`/about`}
                    className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                  >
                    <AiOutlineInfoCircle className='text-primary-500 group-hover:text-white' />
                    <span>Despre</span>
                  </Link>
                  <button
                    className='px-6 py-3 xs:px-4 xs:py-2 duration-300 [&:not(:last-child)]:border-b-[0.1px] [&:not(:last-child)]:border-secondary-100 hover:bg-primary-500 hover:text-white w-full whitespace-nowrap [&:first-child]:rounded-t [&:last-child]:rounded-b flex items-center gap-2 group'
                    onClick={logoutUser}
                  >
                    <FiLogOut className='text-primary-500 group-hover:text-white' />
                    <span>Deconectare</span>
                  </button>
                </ul>
              ))}
          </div>
        ) : (
          <div className='flex items-center gap-2 xs:gap-6'>
            <Link to='/login' className='text-gray-200 hover:text-white duration-300 text-sm xs:text-base md:text-lg'>
              Conectare
            </Link>
            <Link to='/contact' className='text-gray-200 hover:text-white duration-300 text-sm xs:text-base md:text-lg'>
              Contact
            </Link>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;

export function formatDate(dateString, withTime = false) {
  const date = new Date(Date.parse(dateString));

  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

  return withTime ? `${day}.${month}.${year} ${hours}:${minutes}:${seconds}` : `${day}.${month}.${year}`;
}

export function isCifValid(cif) {
  let key = '753217532'.split('').reverse();

  cif = cif.trim().split('').reverse().map(Number);

  let sum = 0;

  for (let i = 1; i < cif.length; i++) {
    sum += parseInt(cif[i]) * parseInt(key[i - 1]);
  }

  const rest = (sum * 10) % 11 === 10 ? 0 : (sum * 10) % 11;

  return rest === cif[0];
}

export function isCnpValid(cnp) {
  let key = '279146358279';

  cnp = cnp.trim().split('').map(Number);

  if (cnp.length !== 13) {
    return false;
  }

  let sum = 0;

  for (let i = 0; i <= 11; i++) {
    sum += parseInt(cnp[i]) * parseInt(key[i]);
  }

  let rest = sum % 11;

  if ((rest === 10 && cnp[12] !== 1) || (rest < 10 && rest !== cnp[12])) {
    return false;
  }

  return true;
}

export function getUsername(user) {
  if (!user) return;

  return !!+user.user_is_pf ? `${user.user_firstname} ${user.user_lastname}` : user.user_company_name;
}

export function getOmulonAddress(omulon = null) {
  return omulon
    ? [
        omulon.omulon_denloc || '',
        omulon.omulon_d_str || '',
        omulon.omulon_d_nr || '',
        omulon.omulon_d_bloc || '',
        omulon.omulon_d_jd || ''
      ]
        .filter((x) => x.length)
        .join(', ')
    : '';
}

export function getFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function setToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromLocalStorage(key) {
  localStorage.removeItem(key);
}

export function timeSince(date) {
  let seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return 'in urma cu ' + Math.floor(interval) + ' years';
  }

  interval = seconds / 2592000;

  if (interval > 1) {
    return 'in urma cu ' + Math.floor(interval) + ' luni';
  }

  interval = seconds / 86400;

  if (interval > 1) {
    return 'in urma cu ' + Math.floor(interval) + ' zile';
  }

  interval = seconds / 3600;

  if (interval > 1) {
    return 'in urma cu ' + Math.floor(interval) + ' ore';
  }

  interval = seconds / 60;

  if (interval > 1) {
    return 'in urma cu ' + Math.floor(interval) + ' minute';
  }

  return 'in urma cu ' + Math.floor(seconds) + ' secunde';
}

function timeAgo(date) {
  const timeUnits = {
    year: 3600 * 24 * 365,
    month: 3600 * 24 * 30,
    week: 3600 * 24 * 7,
    day: 3600 * 24,
    hour: 3600,
    minute: 60,
    second: 1
  };
}

export function subtractMonths(date, months) {
  date.setMonth(date.getMonth() - months);

  return date;
}

export function subtractHours(date, hours) {
  date.setHours(date.getHours() - hours);

  return date;
}

export function subtractDays(date, days) {
  date.setDate(date.getDate() - days);

  return date;
}

export function isImageFile(fileName) {
  const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  return imageExtensions.includes(extension);
}

export function getFileNameWithoutExtension(fileName) {
  return fileName.substring(0, fileName.lastIndexOf('.'));
}

export function downloadFile(fileName, fileUrl, newTab = false) {
  if (newTab) {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.target = '_blank';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    fetch(fileUrl, {
      mode: 'no-cors'
    })
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      })
      .catch(console.error);
  }
}

const Spinner = ({ size = '25' }) => {
  return (
    size && (
      <span
        className='loader'
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderWidth: `${size[0]}px`
        }}
      ></span>
    )
  );
};

export default Spinner;

import React from 'react';

const AboutPage = () => {
  return (
    <div className='flex flex-col gap-4'>
      <h1 className='title'>Despre</h1>
      <h3 className='subTitle'>
        Versiune <span className='text-primary-500'>0.9</span>
      </h3>
    </div>
  );
};

export default AboutPage;

import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const infosApi = createApi({
  reducerPath: 'infosApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: window.API_URL
  }),
  tags: ['Infos'],
  endpoints: (builder) => ({
    getInfos: builder.query({
      query: () => `/infos.php?all=1`,
      providesTags: ['Infos']
    }),
    getInfosByDepartment: builder.query({
      query: (departmentId) => `/infos.php?all=1&department_id=${departmentId}`,
      providesTags: ['Infos']
    }),
    addInfo: builder.mutation({
      query: (data) => ({
        url: '/infos.php',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Infos']
    }),
    removeInfo: builder.mutation({
      query: (id) => ({
        url: `/infos.php?info_id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Infos']
    })
  })
});

export const { useGetInfosQuery, useRemoveInfoMutation, useAddInfoMutation, useGetInfosByDepartmentQuery } = infosApi;

export default infosApi;

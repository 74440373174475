import ReCAPTCHA from 'react-google-recaptcha';
import { createRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { useAddUserMutation } from '../../api/usersApi';
import { isCnpValid, isCifValid } from '../../utils/fn';
import SuccessMessage from '../../components/SuccessMessage';
import Spinner from '../../components/Spinner';

const schema = yup.object().shape(
  {
    user_cui_cnp: yup.number().typeError('Campul trebuie sa contina doar cifre!').required('Camp obligatoriu!'),
    user_firstname: yup.string().when('user_company_name', {
      is: '',
      then: yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!')
    }),
    user_lastname: yup.string().when('user_company_name', {
      is: '',
      then: yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!')
    }),
    user_email: yup.string().email('Email invalid!').required('Camp obligatoriu'),
    user_email_confirmation: yup.string().oneOf([yup.ref('user_email'), null], 'Email-ul nu se potriveste!'),
    user_phone: yup.number().typeError('Campul trebuie sa contina doar cifre!').required('Camp obligatoriu'),
    user_communication_address: yup.string().required('Camp obligatoriu!'),
    user_company_name: yup.string().when(['user_firstname', 'user_lastname'], {
      is: '',
      then: yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!')
    })
  },
  [
    ['user_lastname', 'user_company_name'],
    ['user_firstname', 'user_company_name']
  ]
);

const successTitle = 'Mulțumim că ați creat un cont la serviciul nostru de taxe și impozite.';
const successSubtitle = 'Pentru a activa contul creat, vă rugăm să urmați instrucțiunile din e-mailul trimis pe adresa dumneavoastră de email.';

const RegisterPage = () => {
  const recaptchaRef = createRef();
  const [isGdprChecked, setIsGdprChecked] = useState(false);
  const [addUser] = useAddUserMutation();
  const [isIdCodeValid, setIsIdCodeValid] = useState(false);
  const [idCode, setIdCode] = useState('');
  const [personType, setPersonType] = useState('');
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(data) {
    setIsLoading(true);
    
    const token = await recaptchaRef.current.executeAsync();

    if (!token) {
      return;
    }

    delete data['user_email_confirmation'];

    data['user_is_pf'] = personType === 'pf' ? true : false;

    try {
      const { success = false, message = '' } = await addUser(data).unwrap();

      if (success) {
        setIsRegistrationSuccess(true);
      } else {
        toast.error(message);
        window.grecaptcha.reset();
      }
    } catch (error) {
      console.error(error);
      window.grecaptcha.reset();
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isCifValid(idCode)) {
        clearErrors('user_cui_cnp');
        setIsIdCodeValid(true);
        setPersonType('pj');
      } else if (isCnpValid(idCode)) {
        clearErrors('user_cui_cnp');
        setIsIdCodeValid(true);
        setPersonType('pf');
      } else {
        if (idCode.trim()) {
          setError('user_cui_cnp', { message: 'CUI/CNP invalid!' });
        }
        setIsIdCodeValid(false);
        setPersonType('');
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [idCode, clearErrors, setError]);

  if (!isRegistrationSuccess) {
    return (
      <div className='flex flex-col items-center justify-center h-full w-full minHeightCalc'>
        <form className='form md:w-[35rem]' onSubmit={handleSubmit(onSubmit)}>
          <h1 className='self-start subTitle'>Creare cont</h1>
          <div className='formControl'>
            <label className='formLabel'>CUI/CNP:</label>
            <input
              type='text'
              className={`formInput ${errors?.user_cui_cnp && 'border-[0.1px] border-primary-500'}`}
              {...register('user_cui_cnp')}
              onChange={(e) => setIdCode(e.target.value)}
            />
            <p className='inputErrorMessage'>{errors?.user_cui_cnp?.message}</p>
          </div>
          {isIdCodeValid && (
            <>
              {personType === 'pj' ? (
                <div className='formControl'>
                  <label className='formLabel'>Denumire societate:</label>
                  <input
                    autoComplete='newCompanyName'
                    type='text'
                    className={`formInput ${errors?.user_company_name && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_company_name')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_company_name?.message}</p>
                </div>
              ) : personType === 'pf' ? (
                <div className='flexBetween flex-1 gap-2 flex-wrap xs:flex-nowrap w-full'>
                  <div className='flex-grow w-full sm:max-w-[50%]'>
                    <label className='formLabel'>Nume:</label>
                    <input
                      type='text'
                      className={`formInput ${errors?.user_firstname && 'border-[0.1px] border-primary-500'}`}
                      {...register('user_firstname')}
                    />
                    <p className='inputErrorMessage'>{errors?.user_firstname?.message}</p>
                  </div>
                  <div className='flex-grow w-full sm:max-w-[50%]'>
                    <label className='formLabel'>Prenume:</label>
                    <input
                      type='text'
                      className={`formInput ${errors?.user_lastname && 'border-[0.1px] border-primary-500'}`}
                      {...register('user_lastname')}
                    />
                    <p className='inputErrorMessage'>{errors?.user_lastname?.message}</p>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='flexBetween flex-1 gap-2 flex-wrap xs:flex-nowrap w-full'>
                <div className='w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Email:</label>
                  <input
                    autoComplete='newEmail'
                    type='text'
                    className={`formInput ${errors?.user_email && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_email')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_email?.message}</p>
                </div>
                <div className='w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Confirmare email:</label>
                  <input
                    autoComplete='confirmEmail'
                    type='text'
                    className={`formInput ${errors?.user_email_confirmation && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_email_confirmation')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_email_confirmation?.message}</p>
                </div>
              </div>
              <div className='flexBetween flex-1 gap-2 flex-wrap xs:flex-nowrap w-full'>
                <div className='w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Telefon:</label>
                  <input
                    autoComplete='newPhone'
                    type='text'
                    className={`formInput ${errors?.user_phone && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_phone')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_phone?.message}</p>
                </div>
                <div className='w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Adresa de comunicare:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_communication_address && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_communication_address')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_communication_address?.message}</p>
                </div>
              </div>
              <div className='formControl flex-row items-center justify-start gap-2 mb-5'>
                <input
                  type='checkbox'
                  id='checkbox-gdpr'
                  checked={isGdprChecked}
                  className='mr-2'
                  {...register('user_is_gdpr_accepted')}
                  onChange={(e) => setIsGdprChecked((prev) => !prev)}
                />
                <label className='formLabel text-base' htmlFor='checkbox-gdpr'>
                  Sunt de acord cu expunerea datelor mele cu caracter personal:
                </label>
              </div>
            </>
          )}
          <div className='flex items-center self-start'>
            <button
              type='submit'
              className='btn btn-dark self-start width-3/4 xs:max-w-fit mr-2'
              disabled={!isGdprChecked || !isIdCodeValid}
            >
              Inregistrare
            </button>
            {isLoading && <Spinner />}
          </div>
          <p className='self-start text-sm'>
            Ai deja un cont? Conecteaza-te de{' '}
            <Link to='/login' className='font-bold link'>
              aici
            </Link>
            !
          </p>
          <ReCAPTCHA
            ref={recaptchaRef}
            size='invisible'
            className='invisible opacity-0 pointer-events-none'
            sitekey={window.RECAPTCHA_SITE_KEY}
          />
        </form>
      </div>
    );
  } else {
    return <SuccessMessage title={successTitle} subtitle={successSubtitle}/>;
  }
};

export default RegisterPage;
